import React from 'react'
import { Link } from 'gatsby'

import {
  ButtonGroup,
  Button,
  P,
  Paragraph,
  H1,
} from '@allied-solutions/affinity'

import { useDirectToFlow, useRedirectToEnterRefId } from '../../../hooks'
import {
  AppLayout,
  ConfirmBorrowerInfo,
  SubmitInsuranceLayout,
} from '../../../components'

const ConfirmBorrowerInfoPage = ({ location }) => {
  useRedirectToEnterRefId()
  const directToFlow = useDirectToFlow()

  return (
    <SubmitInsuranceLayout location={location}>
      <AppLayout
        leftSide={
          <>
            <Paragraph
              mb={6}
              typeStyle="labelLarge"
              textAlign="left"
              color="gray600"
            >
              Trying again…
            </Paragraph>
            <H1 typeStyle="headlineMedium" fontWeight={900}>
              Is this information correct now?
            </H1>
            <Paragraph typeStyle="bodyMedium" textAlign="left" color="gray600">
              Here’s the information we found that matches that reference ID. If
              it’s still incorrect, click “It’s still incorrect.”
            </Paragraph>
          </>
        }
        rightSide={<ConfirmBorrowerInfo />}
        footerButtons={
          <ButtonGroup
            display="flex"
            justifyContent="flex-end"
            css={`
              @media only screen and (max-width: ${props => props.theme[1]}) {
                display: grid;
                direction: rtl;
              }
            `}
          >
            <ButtonGroup.Button
              id="EnterRefId__ConfirmInfo__Button--incorrect"
              as={Link}
              to="/enter-reference-id/incorrect-info/not-found/"
              css={`
                white-space: nowrap;
                text-decoration: none;
              `}
            >
              <P
                mb={0}
                typeStyle="labelLarge"
                textAlign="right"
                color="gray600"
              >
                It’s still incorrect
              </P>
            </ButtonGroup.Button>
            <Button
              id="EnterRefId__ConfirmInfo__Button--correct"
              minWidth={24}
              ml={['5', '8']}
              size="md"
              rounded
              forwardedAs={Link}
              to={directToFlow()}
            >
              It’s correct
            </Button>
          </ButtonGroup>
        }
      />
    </SubmitInsuranceLayout>
  )
}

export default ConfirmBorrowerInfoPage
